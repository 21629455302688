<template>
    <div class="myAfterSale">
        <!-- <div class="tab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="退款管理" name="1"></el-tab-pane>
                <el-tab-pane label="投诉管理" name="2"></el-tab-pane>
            </el-tabs>
        </div> -->
        <!-- <div class="search">
            <el-input placeholder="输入商品标题或订单进行搜索" v-model="keyword">
                <el-button slot="append">订单搜索</el-button>
            </el-input>
            <span>精简筛选条件</span>
        </div> -->
        <div class="conditions" v-if="false">
            <div class="item">
                <span>退款类型</span>
                <el-select v-model="condition.orderType" placeholder="请选择">
                    <el-option
                    v-for="item in orderTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>退款时间</span>
                <el-date-picker
                v-model="condition.daterange"
                type="daterange"
                range-separator="至"
                start-placeholder="请选择时间范围起始"
                end-placeholder="请选择时间范围结束">
                </el-date-picker>
            </div>
            <div class="item">
                <span>退款状态</span>
                <el-select v-model="condition.evaluteStatus" placeholder="请选择">
                    <el-option
                    v-for="item in evaluteStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>垫付状态</span>
                <el-select v-model="condition.tradingStatus" placeholder="请选择">
                    <el-option
                    v-for="item in tradingStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>客服介入</span>
                <el-select v-model="condition.afterSale" placeholder="请选择">
                    <el-option
                    v-for="item in afterSaleList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div>
                <el-button type="danger">查询</el-button>
            </div>
        </div>

        <div class="conditions">
            <div class="item">
                <span>售后类型</span>
                <el-select v-model="condition.afterType" placeholder="请选择">
                    <el-option
                    v-for="item in afterTypeOpt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="item">
                <span>创建时间</span>
                <el-date-picker
                v-model="condition.daterange"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="请选择时间范围起始"
                end-placeholder="请选择时间范围结束">
                </el-date-picker>
            </div>
            <div class="item">
                <span>支付状态</span>
                <el-select v-model="condition.payStatus" placeholder="请选择">
                    <el-option
                    v-for="item in payStatusOpt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <!-- <div class="item">
                <span>处理状态</span>
                <el-select v-model="condition.evaluteStatus" placeholder="请选择">
                    <el-option
                    v-for="item in evaluteStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div> -->
            <div class="ope">
                <el-button type="danger" @click="clickSearch">查询</el-button>
            </div>
        </div>
        <div class="list" v-if="false">
            <div class="thead cell">
                <div class="goods">商品</div>
                <div class="refundPrice">退款金额</div>
                <div class="applyTime">申请时间</div>
                <div class="serveType">服务类型</div>
                <div class="refundStatus">退款状态</div>
                <div class="tradingOpe">交易操作</div>
            </div>
            <div class="tbody">
                <div class="orderItem" v-for="(order,i) in afterSaleList" :key="i" @click="clickDetail(order)">
                    <div class="info">
                        <div class="left">
                            <el-checkbox v-model="order.checked"></el-checkbox>
                            <span>{{order.createTime.split(' ')[0]}}</span>
                            <span>订单号：{{order.orderSn}}</span>
                        </div>
                        <div class="right">
                            <i class="el-icon-delete"></i>
                        </div>
                    </div>
                    <div class="goodsItem cell" v-for="(goodsItem,j) in order.orderGoodsList" :key="j">
                        <div class="goods">
                            <img :src="goodsItem.goods.cover"/>
                            <div class="goodsInfo">
                                <div class="name">{{goodsItem.goods.name}}</div>
                                <div class="otherInfo">
                                    <span>颜色分类：{{goodsItem.color}}</span>
                                </div>
                                <div class="deliveryTime">发货时间：预计24小时内发货</div>
                            </div>
                        </div>
                        <div class="unitPrice">￥{{goodsItem.goods.ourShopPrice}}</div>
                        <div class="goodsNumber">{{goodsItem.count}}</div>
                        <div class="goodsOpe">
                            <div>申请售后</div>
                            <div>投诉商家</div>
                        </div>
                        <div class="payment">
                            {{goodsItem.goodsPrice}}
                        </div>
                        <div class="tradingStatus">
                            <div>交易成功</div>
                            <div>订单详情</div>
                            <div>查看物流</div>
                        </div>
                        <div class="tradingOpe">
                            <el-button>评价</el-button>
                            <div class="invoice">申请开票</div>
                            <div class="voucher">上传凭证</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="thead cell">
                <div class="orderSn">订单编号</div>
                <div class="payStatus">支付状态</div>
                <div class="createTime">下单时间</div>
                <div class="amount">订单总金额</div>
                <div class="payAmount">已付款金额</div>
                <div class="afterType">售后类型</div>
                <div class="handleStatus">处理状态</div>
                <div class="operate">操作</div>
            </div>
             <div class="tbody">
                 <div class="orderItem" v-for="(order,i) in afterSaleTableList" :key="i" @click="clickDetail(order)">
                    <div class="info" v-if="false">
                        <div class="left">
                            <!-- <el-checkbox v-model="order.checked"></el-checkbox> -->
                            <!-- <span>{{order.createTime.split(' ')[0]}}</span> -->
                            <span>订单编号：{{order.orderSn}}</span>
                        </div>
                        <!-- <div class="right">
                            <i class="el-icon-delete"></i>
                        </div> -->
                    </div>
                    <div class="goodsItem cell">
                        <div class="orderSn">{{order.orderSn}}</div>
                        <div class="payStatus">
                            {{payStatusList[order.payStatus]}}
                        </div>
                        <div class="createTime">{{order.createTime}}</div>
                        <div class="amount">{{order.amount}}</div>
                        <div class="payAmount">
                            {{order.payAmount}}
                        </div>
                        <div class="afterType">
                            {{afterTypes[order.afterType]}}
                        </div>
                        <div class="handleStatus">
                            {{order.afterStatusName}}
                        </div>
                        <div class="operate">
                            <el-button @click.stop="cancelAfter(order)">取消</el-button>
                        </div>
                    </div>
                </div>

                <div class="empty" v-show="afterSaleTableList.length==0">
                    <img src="../../assets/imgs/place.png" />
                    <div>暂无售后订单</div>
                </div>
             </div>
        </div>

        <div class="pagi">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {getAfterSaleList,cancelAfterSale} from "@/axios/api"
export default {
    data(){
        return{
            activeName:"1",
            keyword:"",
            // condition:{
            //     orderType:0,
            //     daterange:"",
            //     nickname:"",
            //     evaluteStatus:0,
            //     tradingStatus:0,
            //     afterSale:0,
            // },
            condition:{
                afterType:0,
                daterange:"",
                payStatus:"",
            },
            orderTypes:[
                {
                    label:"全部",
                    value:0,
                },
                {
                    label:"待付款",
                    value:1,
                },
                {
                    label:"待发货",
                    value:2,
                },
                {
                    label:"待收货",
                    value:3,
                },
                {
                    label:"待评价",
                    value:4,
                },
            ],
            evaluteStatusList:[
                {
                    label:"全部",
                    value:0
                }
            ],
            tradingStatusList:[
                {
                    label:"全部",
                    value:0
                }
            ],
            afterSaleList:[
                {
                    label:"全部",
                    value:0
                }
            ],

            afterSaleTableList:[],

            userInfo:{},
            payStatusList:['未支付','已支付','部分支付'],
            afterTypes:['','退货退款','仅退款','更换货物','返厂维修'],
            afterTypeOpt:[
                {label:"全部",value:0},
                {label:"退货退款",value:1},
                {label:"仅退款",value:2},
                {label:"更换货物",value:3},
                {label:"返厂维修",value:4},
            ],
            payStatusOpt:[
                {label:"未支付",value:0},
                {label:"已支付",value:1},
                {label:"部分支付",value:2},
            ],
            pageSize:10,
            currentPage4:1,
            total:0,
        }
    },
    methods: {
        handleSizeChange(val) {
            // // console.log(`每页 ${val} 条`);
            this.pageSize=val;
            this.gainAfterSaleList()
        },
        handleCurrentChange(val) {
            // // console.log(`当前页: ${val}`);
            this.currentPage4=val;
            this.gainAfterSaleList();
        },
        handleClick(){
            // this.gainOrderList();
        },
        // 获取售后列表
        gainAfterSaleList(param){
            var obj={}
            if(param){
                obj=JSON.parse(JSON.stringify(param))
            }
            obj.pageNum=this.currentPage4,
            obj.pageSize=this.pageSize,
            obj.userId=this.userInfo.userId,

            getAfterSaleList(obj).then(res=>{
                // console.log("售后列表",res);
                if(res.code==200){
                    this.afterSaleTableList=res.data.rows;
                    this.total=Number(res.data.total)
                }else{
                    // this.$message(res.msg||res.message)
                    this.$commonFun.wrongTip(this,res.msg)
                }
            })
        },
        // 点击详情
        clickDetail(afterSaleOrder){
            // console.log(afterSaleOrder)
            this.$router.push({
                path:"/personal/afterSaleDetail",
                // query:{afterOrderId:afterSaleOrder.afterOrderId},
                query:{afterOrderId:1}
            })
        },
        // 取消售后
        cancelAfter(order){
            this.$confirm("确认取消售后申请吗",'提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                cancelAfterSale({
                    afterOrderId:order.afterOrderId,
                    orderId:order.orderId,
                    afterType:order.afterType
                }).then(res=>{
                    // console.log("取消售后结果",res);
                    if(res.code==200){
                        this.gainAfterSaleList()
                    }else{
                        this.$commonFun.wrongTip(this,res.msg);
                    }
                })
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });          
            });
        },
        // 点击查询
        clickSearch(){
            var obj={
                afterType:this.condition.afterType,
                startTime:this.condition.daterange?this.condition.daterange[0]:'',
                endTime:this.condition.daterange?this.condition.daterange[1]:'',
                payStatus:this.condition.payStatus
            }
            this.gainAfterSaleList(obj)
        }
    },
    created() {
        this.userInfo=JSON.parse(localStorage.AOGEYA_userInfo)
        this.gainAfterSaleList();
    },
}
</script>

<style lang="less" scoped>
.myAfterSale{
    .tab{
        .el-tabs{
            /deep/.el-tabs__item{
                font-size: 20px;
                font-weight: 700;
                color: #333;
                &.is-active{
                    color: rgba(255, 87, 51, 1);
                }
            }
            /deep/.el-tabs__active-bar{
                background-color: rgba(255, 87, 51, 1);
                height: 3px;
            }
        }
    }
    .search{
        margin-top: 20px;
        display: flex;
        align-items: center;
        .el-input{
            width: 358px;
        }
        &>span{
            margin-left: 15px;
            font-size: 14px;
            font-weight: 400;
            color: rgba(56, 56, 56, 1);
        }
    }
    .conditions{
        // margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        .item{
            display: flex;
            align-items: center;
            // margin-bottom: 20px;
            &>span{
                margin-right: 10px;
                // width: 80px;
                font-size: 14px;
                font-weight: 400;
                color: #333;
            }
            &>.el-input{
                // flex: 1;
                width: 220px;
            }
            /deep/ .el-date-editor--daterange.el-input__inner{
                width: 460px
            }
            &:nth-child(3n+2){
                margin: 0 20px;
                // margin-bottom: 20px;
                flex: 1;
            }
        }
        .ope{
            margin-left:5px;
        }
    }
    // .list{
    //     margin-top: 20px;
    //     .cell{
    //         display: flex;
    //         align-items: center;
    //         padding: 0 25px;
    //         .goods{
    //             flex: 1,
    //         }
    //         .refundPrice{
    //             width: 140px;
    //         }
    //         .applyTime{
    //             width: 140px;
    //         }
    //         .serveType{
    //             width: 140px;
    //         }
    //         .refundStatus{
    //             width: 140px;
    //         }
    //         .tradingOpe{
    //             width: 140px;
    //         }
    //     }
    //     .thead{
    //         height: 50px;
    //         background: rgba(245, 245, 245, 1);
    //         font-size: 12px;
    //         font-weight: 400;
    //         color: rgba(102, 102, 102, 1);
    //     }
    //     .tbody{
    //         .orderItem{
    //             border-left: 1px solid rgba(237, 237, 237, 1);
    //             border-right: 1px solid rgba(237, 237, 237, 1);
    //             .info{
    //                 height: 50px;
    //                 background: rgba(255, 245, 242, 1);
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: space-between;
    //                 padding: 0 25px;
    //                 .left{
    //                     display: flex;
    //                     align-items: center;
    //                     &>span:nth-child(2){
    //                         font-size: 16px;
    //                         font-weight: 700;
    //                         color: rgba(102, 102, 102, 1);
    //                         margin-left: 5px;
    //                     }
    //                     &>span:nth-child(3){
    //                         font-size: 14px;
    //                         font-weight: 400;
    //                         color: rgba(102, 102, 102, 1);
    //                         margin-left: 10px;
    //                     }
    //                 }
    //                 .right{
    //                     i{
    //                         font-size: 21px;
    //                         color: rgba(166, 166, 166, 1);
    //                         cursor: pointer;
    //                     }
    //                 }
    //             }
    //             .goodsItem{
    //                 padding: 20px 25px;
    //                 align-items: stretch;
    //                 border-bottom: 1px solid rgba(237, 237, 237, 1);
    //                 &:last-child{
    //                     border-bottom: none;
    //                 }
    //                 .goods{
    //                     display: flex;
    //                     align-items: flex-start;
    //                     &>img{
    //                         width: 90px;
    //                         height: 68px;
    //                     }
    //                     .goodsInfo{
    //                         flex: 1;
    //                         margin-left: 10px;
    //                         .name{
    //                             font-size: 12px;
    //                             font-weight: 400;
    //                             letter-spacing: 0px;
    //                             line-height: 17.38px;
    //                             color: #333;
    //                             overflow: hidden;
    //                             display: -webkit-box;
    //                             -webkit-box-orient: vertical;
    //                             -webkit-line-clamp: 3;
    //                             line-clamp: 3;
    //                         }
    //                         .otherInfo{
    //                             margin-top: 5px;
    //                             font-size: 12px;
    //                             font-weight: 400;
    //                             line-height: 17.38px;
    //                             color: rgba(128, 128, 128, 1);

    //                         }
    //                         .deliveryTime{
    //                             margin-top: 15px;
    //                             font-size: 12px;
    //                             font-weight: 400;
    //                             letter-spacing: 0px;
    //                             line-height: 17.38px;
    //                             color: #333;
    //                         }
    //                     }
    //                 }
    //                 .goodsNumber{
    //                     font-size: 14px;
    //                     font-weight: 400;
    //                     color: #333;
    //                 }
    //                 .goodsOpe{
    //                     font-size: 14px;
    //                     font-weight: 400;
    //                     letter-spacing: 0px;
    //                     color: #333;
    //                     &>div{
    //                         margin-bottom: 5px;
    //                         cursor: pointer;
    //                     }
    //                 }
    //                 .payment{
    //                     font-size: 14px;
    //                     font-weight: 400;
    //                     color: #333;
    //                 }
    //                 .tradingStatus{
    //                     font-size: 14px;
    //                     font-weight: 400;
    //                     color: rgba(56, 56, 56, 1);
    //                     cursor: pointer;
    //                     &>div{
    //                         margin-bottom: 5px;
    //                     }
    //                 }
    //                 .tradingOpe{
    //                     font-size: 14px;
    //                     font-weight: 400;
    //                     color: rgba(56, 56, 56, 1);
    //                     .invoice{
    //                         margin-top: 5px;
    //                         cursor: pointer;
    //                     }
    //                     .voucher{
    //                         margin-top: 5px;
    //                         color: rgba(255, 87, 51, 1);
    //                         cursor: pointer;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .list{
        margin-top: 20px;
        .cell{
            display: flex;
            align-items: center;
            padding: 0 25px;
            .orderSn{
                flex:1,
            }
            .payStatus{
                width: 140px;
            }
            .createTime{
                width: 140px;
            }
            .amount{
                width: 140px;
            }
            .payAmount{
                width: 140px;
            }
            .afterType{
                width: 140px;
            }
            .handleStatus{
                width: 140px;
            }
            .operate{
                width: 140px;
            }
        }
        .thead{
            height: 50px;
            background: rgba(245, 245, 245, 1);
            font-size: 12px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
        }
        .tbody{
            font-size:12px;
            color:#333;
            .orderItem{
                border-left: 1px solid rgba(237, 237, 237, 1);
                border-right: 1px solid rgba(237, 237, 237, 1);
                border-bottom: 1px solid rgba(237, 237, 237, 1);
                .info{
                    height: 50px;
                    background: rgba(255, 245, 242, 1);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 25px;
                    .left{
                        display: flex;
                        align-items: center;
                        &>span:nth-child(2){
                            font-size: 16px;
                            font-weight: 700;
                            color: rgba(102, 102, 102, 1);
                            margin-left: 5px;
                        }
                        &>span:nth-child(3){
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(102, 102, 102, 1);
                            margin-left: 10px;
                        }
                    }
                    .right{
                        i{
                            font-size: 21px;
                            color: rgba(166, 166, 166, 1);
                            cursor: pointer;
                        }
                    }
                }
                .goodsItem{
                    padding: 20px 25px;
                    align-items: stretch;
                    border-bottom: 1px solid rgba(237, 237, 237, 1);
                    &:last-child{
                        border-bottom: none;
                    }
                    &>div{
                        display:flex;
                        align-items:center;
                    }
                    .goods{
                        display: flex;
                        align-items: flex-start;
                        &>img{
                            width: 90px;
                            height: 68px;
                        }
                        .goodsInfo{
                            flex: 1;
                            margin-left: 10px;
                            .name{
                                font-size: 12px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 17.38px;
                                color: #333;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                line-clamp: 3;
                            }
                            .otherInfo{
                                margin-top: 5px;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 17.38px;
                                color: rgba(128, 128, 128, 1);

                            }
                            .deliveryTime{
                                margin-top: 15px;
                                font-size: 12px;
                                font-weight: 400;
                                letter-spacing: 0px;
                                line-height: 17.38px;
                                color: #333;
                            }
                        }
                    }
                    .goodsNumber{
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                    .goodsOpe{
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        color: #333;
                        &>div{
                            margin-bottom: 5px;
                            cursor: pointer;
                        }
                    }
                    .payment{
                        font-size: 14px;
                        font-weight: 400;
                        color: #333;
                    }
                    .tradingStatus{
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(56, 56, 56, 1);
                        cursor: pointer;
                        &>div{
                            margin-bottom: 5px;
                        }
                    }
                    .tradingOpe{
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(56, 56, 56, 1);
                        .invoice{
                            margin-top: 5px;
                            cursor: pointer;
                        }
                        .voucher{
                            margin-top: 5px;
                            color: rgba(255, 87, 51, 1);
                            cursor: pointer;
                        }
                    }

                    .operate{
                        .el-button{
                            height:36px;
                            line-height:36px;
                            padding-top:0;
                            padding-bottom:0;
                        }
                    }
                }
            }
            
            .empty{
                width: 100%;
                padding: 50px 0;
                display: block;
                // justify-content: center;
                text-align: center;
                &>img{
                    width: 200px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .pagi{
        margin-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                // background-color: rgba(35, 125, 92, 1)!important;
                background-color: rgba(200, 64, 57, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: rgba(0, 0, 0, 1);
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }
}
</style>